import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {Stack, Grid,Paper} from '@mui/material';
import bannervd from '../assets/bannervideo.mp4';
import {useContext} from 'react';
import { styled } from '@mui/material/styles';
import { UserContext } from '../contexts/user.context.jsx';
import Dashboard from './dashboard.js';
import { useTheme } from '@mui/material/styles';
import {Link} from 'react-router-dom';
import hometwo from '../assets/home.svg'
import { LogoutSharp } from '@mui/icons-material';
import para from '../assets/para.png';
import matematik from '../assets/matematik.png';
import shop from '../assets/shop.png';
import Login from './login.component';
const Home = () =>{
  const { currentUser } = useContext(UserContext);
  const theme = useTheme();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
if(currentUser){
  return(
    <Dashboard/>
  )
}

else{
  return(
    <React.Fragment>
  <Stack spacing={3}>
  <Stack>
  <Box component={Link} to="/" component="img" disablePadding sx={{
    width:'100%',
    marginLeft:0,
  }} disableRipple alt="logo" src={hometwo}/>
  </Stack>
  <Stack sx={{m:3}}>
            <Grid container direction="row" rowSpacing={1} columnSpacing={{ xs: 3, sm: 3, md: 4 }} >
              <Grid item xs={12} md={4}>
              <Stack sx={{justifyContent:'center', alignItems:'center',display:'flex' , minWidth:300}}>
                <Item>
                <Box component="img" sx={{
                  height: "20em",
                  m: 2
                }} disableRipple alt="logo" src={para} />
                <Typography>Oyunlarlarımızı oynayarak çocuğunuzla güzel vakit geçirin ve onun yeteneklerinin gelişmesine yardımcı olun.</Typography>
                </Item>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
              <Stack sx={{justifyContent:'center', alignItems:'center',display:'flex' , minWidth:300}}>
                <Item>
                <Box component="img" sx={{
                  height: "20em",
                  m: 2
                }} disableRipple alt="logo" src={matematik} />
                <Typography>Çocuğunuzun dört işlem ve diğer temel matematik yeteneklerinin hızla geliştiğine şahit olun.</Typography>
                </Item>
                </Stack>
              </Grid>            
              <Grid item xs={12} md={4}>
              <Stack sx={{justifyContent:'center', alignItems:'center',display:'flex' , minWidth:300}}>
                <Item>
                <Box component="img" sx={{
                  height: "20em",
                  m: 2
                }} disableRipple alt="logo" src={shop} />
                <Typography>Günlük alışverişlerde çocuğunuz hem daha özgüvenli olsun hem de rahat hissetsin.</Typography>
                </Item>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
  </Stack>
  <Stack>
  <Login/>
  </Stack>
  </React.Fragment>
  )
}
}

export default Home;
