import React from 'react';

import { Typography, Paper, Stack, useMediaQuery } from '@mui/material';
import { link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';

export default function About() {

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const mobil = (
    <React.Fragment>
      <Stack direction="column" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Paper elevation={3} sx={{ width: 600, height: 1150, justifyContent: 'center', alignItems: 'center', m: 4 }}>
          <Typography sx={{ m: 2, mt: 5 }}>
          Amacımız, Down Sendromlu ve Otizmli kişileri hedef alarak öğrenme güçlüklerini yenmek için birden fazla oyun tasarlayıp bunları bir platform altında toplamak ve dünyadaki herkese bu erişimi sunmak. Öğrenmek en temel haklarımız arasındadır ve bunu herkese gösterip öğrenmeyi demokratikleştirmek ve böylece herkese eşit ve adil bir yaşam sunmayı hedefledik. 
          </Typography>
          <Typography sx={{ m: 2 }}>
          Down sendromlu ve Otizmli çocukların sıklıkla karşılaştığı öğretim güçlüklerinin üstesinden gelmek için bunların sebep olduğu problemlere yönelik oyunlar gelistiriyoruz. Öğrenim güçlüğü yaşadıkları alanların üzerinde durarak günlük hayatlarında karşılaştıkları zorlukları oyunlar ile minimize etmek üzere bu oyunları sizlere sunuyoruz.
          </Typography>
          <Typography sx={{ m: 2 }}>
          Down Sendromlu ve Otizmli çocukların güvenli bir şekilde zaman geçirebileceği, eğlenirken öğrenebileceği ve velilerinin ilerlemelerini takip edebileceği platformumuzu geliştirirken araştırma yaparak hangi tip oyunların, hangi öğrenim yöntemlerinin daha efektif olduğunu, öğrenme sürecini ve kullanıcı deneyimini nasıl iyileştirdiğini tespit ediyoruz. Bunlar üzerinden de öğrenim güçlüğü çeken bireyler ve çocukların kullanıp faydalanabileceği daha iyi bir öğrenme platformu tasarlıyoruz, topladığımız verileri paylaşıyoruz
          </Typography>
          <Typography sx={{ m: 2 }}>
          Çeşitli sivil toplum kuruluşlarıyla çalışarak öğrenim güçlüğüne sahip olan bireylere destek olabilmek amacıyla oluşturduğumuz bu platformu herkes tarafından ulaşılabilir bir platform haline getirmek üzerine çalışıyoruz. Öğrenim güçlüğünün herkeste aynı seviyede ve şekilde olmadığının farkında olup birden fazla geliştirdiğimiz oyunla hedef kitlemizi olabildiğince genişletmeye çalışıyoruz. . 
          </Typography>
          <Typography sx={{ m: 2 }}>
          Bizler Down Sendromu ve/veya otizm sebebiyle yaşanan öğrenme güçlüğü çeken çocuklara elimizden geldiğince çeşitli oyunlarla yardımcı olmaya çalışıyoruz. Hem sosyal hem de akademik alanlardaki gelişimlerini maksimum seviyeye çıkarabilmek için tasarladığımız platform ve oyunlarımızla onlara yalnız olmadıklarını gösteriyoruz. Bu süreçte hem çocuklarımızın elinden tutuyor hem de bizim bu tür rahatsızlıklarda yaşanması muhtemel zorlukları gözlemliyor ve çocuklarımıza uygun kişisel dokümanlar hazırlıyoruz.
          </Typography>
          <Typography sx={{ m: 2 }}>
          Biz bir grup liseli öğrenci olarak öğrenmenin en temel hak olduğuna inanıyoruz. Bu prensipten yola çıkarak öğrenim güçlüklerini geliştirdiğimiz teknolojiyle azaltıyor ve araştırmalarımızla platformumuzu günbegün geliştirerek dünyanın farklı bölgelerinden çocuklara yardım ediyoruz. Bizlere bu mail adresinden ulaşabilirsiniz:
          </Typography>
          <Typography sx={{ color: 'primary.main', m: 2 }}>engelsizoyunlarprojesi@gmail.com</Typography>
        </Paper>
        <Paper elevation={3} sx={{ width: 600, height: 1150, justifyContent: 'center', alignItems: 'center', m: 4 }}>
          <Typography sx={{ m: 2, mt: 5 }}>
          Our goal is to overcome the learning difficulties by designing games aiming at the down syndrome and otizm kids and also presenting these games to everyone in the world. Learning is one of our fundamental rights and we wanted to democratize learning so by doing that we aimed to present everyone a fair and equal life.  
          </Typography>
          <Typography sx={{ m: 2 }}>
          To overcome the difficulties that children with Down syndrome and autism face we develop games that help with their problems caused by their learning difficulties. We focus on the areas that children experience learning difficulties and present games that minimize the hardships they face during their daily lives.
          </Typography>
          <Typography sx={{ m: 2 }}>
          Our platform aims to provide children with Down Syndrome or Autism a safe and fun learning environment and let their parents track their progress. While developing our website, our team has researched the most efficient games and learning methods for the kids and how these games affect the kids’ learning process and  utilize the user experience. Using what we have learned, we are designing our platform in hopes of helping people and kids with learning disabilities and sharing the data we are collecting. 
          </Typography>
          <Typography sx={{ m: 2 }}>
          Through working with various non-governmental organizations, we are working on making this platform,  which aims to support people with learning difficulties, accessible to everyone. We are aware of the fact that not everyone has the same level and form of learning difficulties, therefore by developing more than just one game we try to expand our target audience as much as possible. 
          </Typography>
          <Typography sx={{ m: 2 }}>
          As a group our priority is to help children, who are struggling with learning. Because of Down syndrome and autism, learning can be challenging sometimes. Therefore,we try to develop various games in order to make children improve their social and academic skills. While doing that we show them that they are not alone in this path. Within this period we both give a hand to our children, observe what kind of challenges we may face and think about how to overcome them. By doing this, we also prepare personal documents to encourage our beloved children. 
          </Typography>
          <Typography sx={{ m: 2 }}>
          We, as a group of high school students, believe that education is an innate right. Starting from this principle, we are minimizing the learning disorders through our technology and improving the platform continuously by conducting various experiments to help children from all around the world. You can reach us at 
          </Typography>
          <Typography sx={{ color: 'primary.main', m: 2 }}>engelsizoyunlarprojesi@gmail.com</Typography>
        </Paper>
      </Stack>
    </React.Fragment>
  )

  const desktop = (
    <React.Fragment>
      <Stack direction="row" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Paper elevation={3} sx={{ width: 600, height: 1150, justifyContent: 'center', alignItems: 'center', m: 4 }}>
        <Typography sx={{ m: 2, mt: 5 }}>
        Amacımız, Down Sendromlu ve Otizmli kişileri hedef alarak öğrenme güçlüklerini yenmek için birden fazla oyun tasarlayıp bunları bir platform altında toplamak ve dünyadaki herkese bu erişimi sunmak. Öğrenmek en temel haklarımız arasındadır ve bunu herkese gösterip öğrenmeyi demokratikleştirmek ve böylece herkese eşit ve adil bir yaşam sunmayı hedefledik. 
        </Typography>
        <Typography sx={{ m: 2 }}>
        Down sendromlu ve Otizmli çocukların sıklıkla karşılaştığı öğretim güçlüklerinin üstesinden gelmek için bunların sebep olduğu problemlere yönelik oyunlar gelistiriyoruz. Öğrenim güçlüğü yaşadıkları alanların üzerinde durarak günlük hayatlarında karşılaştıkları zorlukları oyunlar ile minimize etmek üzere bu oyunları sizlere sunuyoruz.
        </Typography>
        <Typography sx={{ m: 2 }}>
        Down Sendromlu ve Otizmli çocukların güvenli bir şekilde zaman geçirebileceği, eğlenirken öğrenebileceği ve velilerinin ilerlemelerini takip edebileceği platformumuzu geliştirirken araştırma yaparak hangi tip oyunların, hangi öğrenim yöntemlerinin daha efektif olduğunu, öğrenme sürecini ve kullanıcı deneyimini nasıl iyileştirdiğini tespit ediyoruz. Bunlar üzerinden de öğrenim güçlüğü çeken bireyler ve çocukların kullanıp faydalanabileceği daha iyi bir öğrenme platformu tasarlıyoruz, topladığımız verileri paylaşıyoruz
        </Typography>
        <Typography sx={{ m: 2 }}>
        Çeşitli sivil toplum kuruluşlarıyla çalışarak öğrenim güçlüğüne sahip olan bireylere destek olabilmek amacıyla oluşturduğumuz bu platformu herkes tarafından ulaşılabilir bir platform haline getirmek üzerine çalışıyoruz. Öğrenim güçlüğünün herkeste aynı seviyede ve şekilde olmadığının farkında olup birden fazla geliştirdiğimiz oyunla hedef kitlemizi olabildiğince genişletmeye çalışıyoruz. . 
        </Typography>
        <Typography sx={{ m: 2 }}>
        Bizler Down Sendromu ve/veya otizm sebebiyle yaşanan öğrenme güçlüğü çeken çocuklara elimizden geldiğince çeşitli oyunlarla yardımcı olmaya çalışıyoruz. Hem sosyal hem de akademik alanlardaki gelişimlerini maksimum seviyeye çıkarabilmek için tasarladığımız platform ve oyunlarımızla onlara yalnız olmadıklarını gösteriyoruz. Bu süreçte hem çocuklarımızın elinden tutuyor hem de bizim bu tür rahatsızlıklarda yaşanması muhtemel zorlukları gözlemliyor ve çocuklarımıza uygun kişisel dokümanlar hazırlıyoruz.
        </Typography>
        <Typography sx={{ m: 2 }}>
        Biz bir grup liseli öğrenci olarak öğrenmenin en temel hak olduğuna inanıyoruz. Bu prensipten yola çıkarak öğrenim güçlüklerini geliştirdiğimiz teknolojiyle azaltıyor ve araştırmalarımızla platformumuzu günbegün geliştirerek dünyanın farklı bölgelerinden çocuklara yardım ediyoruz. Bizlere bu mail adresinden ulaşabilirsiniz:
        </Typography>
        <Typography sx={{ color: 'primary.main', m: 2 }}>engelsizoyunlarprojesi@gmail.com</Typography>
        </Paper>
        <Paper elevation={3} sx={{ width: 600, height: 1150, justifyContent: 'center', alignItems: 'center', m: 4 }}>
        <Typography sx={{ m: 2, mt: 5 }}>
        Our goal is to overcome the learning difficulties by designing games aiming at the down syndrome and otizm kids and also presenting these games to everyone in the world. Learning is one of our fundamental rights and we wanted to democratize learning so by doing that we aimed to present everyone a fair and equal life.  
        </Typography>
        <Typography sx={{ m: 2 }}>
        To overcome the difficulties that children with Down syndrome and autism face we develop games that help with their problems caused by their learning difficulties. We focus on the areas that children experience learning difficulties and present games that minimize the hardships they face during their daily lives.
        </Typography>
        <Typography sx={{ m: 2 }}>
        Our platform aims to provide children with Down Syndrome or Autism a safe and fun learning environment and let their parents track their progress. While developing our website, our team has researched the most efficient games and learning methods for the kids and how these games affect the kids’ learning process and  utilize the user experience. Using what we have learned, we are designing our platform in hopes of helping people and kids with learning disabilities and sharing the data we are collecting. 
        </Typography>
        <Typography sx={{ m: 2 }}>
        Through working with various non-governmental organizations, we are working on making this platform,  which aims to support people with learning difficulties, accessible to everyone. We are aware of the fact that not everyone has the same level and form of learning difficulties, therefore by developing more than just one game we try to expand our target audience as much as possible. 
        </Typography>
        <Typography sx={{ m: 2 }}>
        As a group our priority is to help children, who are struggling with learning. Because of Down syndrome and autism, learning can be challenging sometimes. Therefore,we try to develop various games in order to make children improve their social and academic skills. While doing that we show them that they are not alone in this path. Within this period we both give a hand to our children, observe what kind of challenges we may face and think about how to overcome them. By doing this, we also prepare personal documents to encourage our beloved children. 
        </Typography>
        <Typography sx={{ m: 2 }}>
        We, as a group of high school students, believe that education is an innate right. Starting from this principle, we are minimizing the learning disorders through our technology and improving the platform continuously by conducting various experiments to help children from all around the world. You can reach us at 
        </Typography>
        <Typography sx={{ color: 'primary.main', m: 2 }}>engelsizoyunlarprojesi@gmail.com</Typography>
        </Paper>
      </Stack>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      {matches ? mobil : desktop}
    </React.Fragment>
  )
}