import React from 'react';
import {Grid, Paper, Box, Card, CardContent, CardMedia, Typography, CardActionArea} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import {Link} from 'react-router-dom';
import {useContext} from 'react';
import { UserContext } from '../contexts/user.context.jsx';

export default function Games(){

var currentUserGroup = "";

const { currentUser } = useContext(UserContext);


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

if(currentUser.roles === "gameone" || currentUser.roles === ""){
  return(
    <Box sx={{ flexGrow: 1 }}>
     <Grid sx={{display: 'flex', aligItems: 'center',justifyContent: 'center'}}container spacing={{ xs: 4, md: 6 }} columns={{ xs: 4, sm: 8, md: 12 }}>
         <Grid sx={{textDecoration:"none", display: 'flex', aligItems: 'center',justifyContent: 'center'}} component={Link} to="/gameone" item xs={4} sm={6} md={4}>
          <Item>
           <Card sx={{ minWidth: 345 }}>
            <CardActionArea>
            <CardMedia
              component="img"
              height="300"
              image={require('../assets/game-one.png')}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Parayı Anla
                    </Typography>
                <Typography variant="body2" color="text.secondary">
                Para algısını güçlendiren Parayı Anla oyunu çocukların günlük alışverişlerinde kolaylık sağlamasının yanı sıra temel matematik gelişimlerine yardımcı olur. 
                </Typography>
                </CardContent>
                </CardActionArea>
            </Card>
           </Item>
         </Grid>
  
     </Grid>
   </Box>
  )
}
else if(currentUser.roles === "gametwo"){
  return(
    <Box sx={{ flexGrow: 1 }}>
     <Grid sx={{display: 'flex', aligItems: 'center',justifyContent: 'center'}}container spacing={{ xs: 4, md: 6 }} columns={{ xs: 4, sm: 8, md: 12 }}>
         <Grid sx={{textDecoration:"none", display: 'flex', aligItems: 'center',justifyContent: 'center'}} component={Link} to="/gametwo" item xs={4} sm={6} md={4}>
          <Item>
           <Card sx={{ minWidth: 345 }}>
            <CardActionArea>
            <CardMedia
              component="img"
              height="300"
              image={require('../assets/game-one.png')}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Parayı Anla
                    </Typography>
                <Typography variant="body2" color="text.secondary">
                  Para algısını güçlendiren Parayı Anla oyunu çocukların günlük alışverişlerinde kolaylık sağlamasının yanı sıra temel matematik gelişimlerine yardımcı olur. 
                </Typography>
                </CardContent>
                </CardActionArea>
            </Card>
           </Item>
         </Grid>
     </Grid>
   </Box>
  ) 
}

}
