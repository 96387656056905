import styled from "@emotion/styled";
import {
  Grid,
  List,
  ListItemText,
  Typography,
  Button,
  Stack,
  Container,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import SendIcon from "@mui/icons-material/Send";

const FooterTitle = styled(Typography)(() => ({
  textTransform: "uppercase",
  marginBottom: "1em",
}));

const SubscribeTf = styled(TextField)(() => ({
  ".MuiInputLabel-root": {
    color: 'secondary.main',
  },

  ".MuiInput-root::before": {
    borderBottom: `1px solid secondary.main`,
  },
}));

export default function Footer() {
  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        color: 'white',
        p: { xs: 4, md: 10 },
        pt: 12,
        pb: 12,
        fontSize: { xs: '12px', md: '14px' }
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item md={6} lg={4}>
          <FooterTitle variant="body1">Hakkımızda</FooterTitle>
          <Typography variant="caption2">
            Biz Engelsiz Oyunlar projesi olarak eğitici oyunlarak geliştirerek öğrenme güçlükleri yaşayan çocukları eğitim ve öğretim alanında desteklemeyi hedefliyoruz. Teknoloji ve bilimsel araştırmaların gücüne inanıyoruz.
          </Typography>
          <Box
            sx={{
              mt: 4,
              color: 'primary.main',
            }}
          >
            <FacebookIcon sx={{ mr: 1 }} />
            <TwitterIcon sx={{ mr: 1 }} />
            <InstagramIcon />
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
}
