import React from 'react';

const Contact = () =>{
  return(
    <div>
      <p>Contact page</p>
    </div>
  );
}

export default Contact;
